import { ETFCard } from '@cfra-nextgen-frontend/shared';
import { ProfileCard } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/ProfileCard';
import { ScreenerHome } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import { ProfileFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/ProfileForm';
import { fillTemplate } from '@cfra-nextgen-frontend/shared/src/components/Screener/utils/templates';
import { OperationTypes, UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Modal, getEntityAssociatedItems, getEntityFilterReqParams, getEntityScreenerReqParam } from './utils';

export type ProfilePageProps = {
    entityType: UserPlatformManagementEntityTypes;
    editProfileButtonText: string;
    getProfileTitle: (data: any) => string;
    getUpdateConfirmTitle?: (data: any) => string;
    CustomProfileForm?: (props: ProfileFormProps) => JSX.Element;
    entityId?: number;
    relatedParams?: Array<{ key: string, value: string }>
};
export function ProfilePage(props: ProfilePageProps) {
    const { id } = useParams();
    const [entityId] = useState<number | undefined>(id ? parseInt(id) : undefined);
    const navigate = useNavigate();

    useEffect(() => {
        if (Number.isNaN(entityId)) {
            navigate('/');
        }
    }, [navigate, entityId]);

    // use useMemo to avoid unnecessary re-renders if useBlocker is used deeper in the tree
    const children = useMemo(() => {
        return <ProfilePageLocal {...props} entityId={entityId} />;
    }, [entityId, props]);

    return children;
}

const containerStyles = { p: 0 };

const containsProfileTitlePlaceholder = (template: string) => template.includes('{profileTitle}');

function ProfilePageLocal({
    entityType,
    editProfileButtonText,
    getProfileTitle,
    getUpdateConfirmTitle,
    CustomProfileForm,
    entityId,
    relatedParams
}: ProfilePageProps) {
    const [profileTitle, setProfileTitle] = useState<string>();
    const [relatedParamsValues, setRelatedParamsValues] = useState<Array<{key: string, value: any}>>([]);

    const entityAssociatedItems = useMemo(() => {
        if (!entityId) {
            return undefined;
        }
        return getEntityAssociatedItems(entityType, entityId);
    }, [entityType, entityId]);

    const entityScreenerReqParams: ReturnType<typeof getEntityScreenerReqParam> | undefined = useMemo(() => {
        if (!entityId) {
            return undefined;
        }
        return getEntityScreenerReqParam(entityType, entityId);
    }, [entityType, entityId]);

    const entityFiltersReqParams: ReturnType<typeof getEntityFilterReqParams> | undefined = useMemo(() => {
        if (!entityId) {
            return undefined;
        }
        return getEntityFilterReqParams(entityType, OperationTypes.EDIT);
    }, [entityType, entityId]);
    
    const entityAssociatedItemsJsx = useMemo(() => {
        if (!entityAssociatedItems) {
            return null;
        }

        const processPresets = (presetValues: Record<string, any>, dataObject: Object) => {
            let presets: Record<string, any> = {};
        
            for (const key in presetValues) {
                const updatedValue = fillTemplate({
                    templateName: 'presetValueTemplate',
                    template: presetValues[key]?.value || presetValues[key],
                    dataObject: dataObject,
                });
        
                if (!updatedValue) {
                    continue;
                }

                let value: any = updatedValue
                if (presetValues[key]?.isArray && !Array.isArray(updatedValue)) {
                    value = [{ key: entityId, value: updatedValue }];
                }
                if (presetValues[key]?.isDirty){
                    value = { value: value, isDirty: true }
                }
                presets[key] = value
            }
            return presets;
        };        

        const result: Array<React.ReactNode> = [];
        for (const item of entityAssociatedItems) {
            const modals: Array<Modal> | undefined = item.modals?.map(({ ModalWrapper, props, formComponent }) => {
                let presets: Record<string, any> = {}

                if (props.presetValues) {
                    //account/xxx/group/xxx - configuration to set the Account correctly.
                    //the profileTitle has the group name instead of the account.
                    if (relatedParamsValues.length > 0) {
                        for (let param of relatedParamsValues) {
                            presets = {
                                ...presets,
                                ...processPresets(props.presetValues, {
                                    [param.key]: param.value,
                                }),
                            };
                        }
                    } else {                
                        if (profileTitle) {
                            presets = processPresets(props.presetValues, {
                                account_name: profileTitle,
                            });
                        }
                    }
                }

                if (!containsProfileTitlePlaceholder(props.title)) {
                    return { ModalWrapper, props: { ...props, presetValues: presets }, formComponent };
                }
                
                return {
                    ModalWrapper,
                    props: {
                        ...props,
                        title:
                            fillTemplate({
                                templateName: 'modalsPropsTitleTemplate',
                                template: props.title,
                                dataObject: {
                                    profileTitle,
                                },
                            }) || '',
                        presetValues: presets,
                    },
                };
            });

            result.push(
                <ScreenerHome
                    cardName={item.screenerProps.cardName || ''}
                    modals={modals}
                    enableTopLeftActionPanel={item.screenerProps.enableTopLeftActionPanel}
                    enableTopRightActionPanel={false}
                    key={item.screenerProps.cardName}
                    rowLevelFiltersConfig={item.screenerProps.rowLevelFiltersConfig}
                    screenerRequestParams={item.screenerProps.screenerRequestParams}
                    useSSRMode={false}
                    maxGridContainerHeightPercentage={0}
                    unlimitedCalculatedHeight
                    {...(item?.screenerProps || {})}
                    showSelectionOnEdit={item.screenerProps.showSelectionOnEdit}
                    EditButtonsVariant={item.screenerProps.EditButtonsVariant}
                    entityType={item.screenerProps.entityType || entityType}
                />,
            );
        }

        return result;
    }, [entityAssociatedItems, profileTitle, relatedParamsValues, entityId]);

    const profileCardJsx = useMemo(() => {
        if (!entityId || !entityFiltersReqParams || !entityScreenerReqParams) {
            return null;
        }

        return (
            <ProfileCard
                id={entityId}
                profileType={entityType}
                filtersReqParams={entityFiltersReqParams}
                screenerReqParams={entityScreenerReqParams}
                getProfileTitle={getProfileTitle}
                getUpdateConfirmTitle={getUpdateConfirmTitle}
                editButtonText={editProfileButtonText}
                CustomProfileForm={CustomProfileForm}
                setProfileTitle={setProfileTitle}
                setRelatedParamsValues={setRelatedParamsValues}
                relatedParams={relatedParams}
            />
        );
    }, [
        editProfileButtonText,
        entityFiltersReqParams,
        entityId,
        entityScreenerReqParams,
        entityType,
        getProfileTitle,
        getUpdateConfirmTitle,
        CustomProfileForm,
        relatedParams
    ]);

    if (!entityId) {
        return null;
    }

    if (!entityAssociatedItems || !entityScreenerReqParams || !entityFiltersReqParams) {
        return null;
    }

    return (
        <>
            <ETFCard.ETFCard containerStyles={containerStyles}>{profileCardJsx}</ETFCard.ETFCard>
            {entityAssociatedItemsJsx}
        </>
    );
}
