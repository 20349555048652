import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { forwardRef, useCallback, useImperativeHandle, useRef } from 'react';
import './AgGrid.scss';
import './paddings.scss';
import { scrollOneColumnTo } from './utils';
import { useDrag } from '@use-gesture/react';
import { resetCursor, setCursor } from '../../utils/cursor';

type AgGridDragScrollProps = {
    gridRef: React.RefObject<AgGridReact<any>>;
    gridContainerRef: React.RefObject<HTMLDivElement>;
    inverseScrollDirection?: boolean;
    useBrowserVerticalScroll?: boolean;
};

export type AgGridDragScrollRef = {
    refreshListeners: () => void;
};

const SCROLL_DELAY = 100; // scroll delay in ms

export const AgGridDragScroll = forwardRef<AgGridDragScrollRef, AgGridDragScrollProps>(
    ({ gridRef, gridContainerRef, inverseScrollDirection, useBrowserVerticalScroll }, ref) => {
        const isMoveCallbackBlockedRef = useRef<boolean>(false);
        const areListenersAddedRef = useRef<boolean>(false);

        const bind = useDrag(({ movement: [mx, my], direction: [dx, dy], event }) => {
            if (isMoveCallbackBlockedRef.current) {
                return;
            }

            isMoveCallbackBlockedRef.current = true;

            const isHorizontalDrag = Math.abs(dx) > Math.abs(dy);

            const handleTimeout = () => {
                setTimeout(() => {
                    isMoveCallbackBlockedRef.current = false;
                }, SCROLL_DELAY);
            };

            if (isHorizontalDrag) {
                const isDragRight = inverseScrollDirection ? dx < 0 : dx > 0;
                scrollOneColumnTo(isDragRight ? 'right' : 'left', gridRef);

                handleTimeout();
                return;
            }

            // if not horizontal drag, then it's vertical drag - handle it below
            if (useBrowserVerticalScroll) {
                window.scrollBy({
                    top: inverseScrollDirection ? -my : my,
                    behavior: 'smooth',
                });

                handleTimeout();
                return;
            }

            // Vertical drag logic: scroll the grid body instead of the window
            const gridContainer = gridContainerRef.current;

            if (gridContainer) {
                const gridBodyElement = gridContainer.querySelector('.ag-body-viewport'); // Query the grid body viewport
                if (gridBodyElement) {
                    gridBodyElement.scrollBy({
                        top: inverseScrollDirection ? -my : my,
                        behavior: 'smooth',
                    });
                }
            }

            handleTimeout();
        });

        const setDragListeners = useCallback(() => {
            if (!gridRef.current || !gridContainerRef.current || areListenersAddedRef.current) {
                return;
            }

            const viewportElement = gridContainerRef.current.querySelector(
                '.ag-center-cols-viewport',
            ) as HTMLDivElement;

            if (!viewportElement) {
                return;
            }

            const { onPointerDown, onPointerMove, onPointerUp, onPointerCancel } = bind();

            const handleOnPointerUp = (event: MouseEvent) => {
                if (event.button !== 0) {
                    return;
                }

                resetCursor();
                onPointerUp?.(event as any);
            };

            const handleOnPointerDown = (event: MouseEvent) => {
                if (event.button !== 0) {
                    return;
                }

                setCursor();
                onPointerDown?.(event as any);
            };

            const handlePointerCancel = (event: MouseEvent) => {
                resetCursor();
                onPointerCancel?.(event as any);
            }

            viewportElement.addEventListener('pointerdown', handleOnPointerDown as any, { passive: true });
            viewportElement.addEventListener('pointermove', onPointerMove as any, { passive: true });
            viewportElement.addEventListener('pointerup', handleOnPointerUp as any, { passive: true });
            viewportElement.addEventListener('pointercancel', handlePointerCancel as any, { passive: true });
            viewportElement.style.touchAction = 'none'; // disable default touch action

            areListenersAddedRef.current = true;
            return () => {
                viewportElement.removeEventListener('pointerdown', handleOnPointerDown as any);
                viewportElement.removeEventListener('pointermove', onPointerMove as any);
                viewportElement.removeEventListener('pointerup', handleOnPointerUp as any);
                viewportElement.removeEventListener('pointercancel', handlePointerCancel as any);
                viewportElement.style.touchAction = ''; // restore default touch action

                areListenersAddedRef.current = false;
            };
        }, [gridContainerRef, gridRef, bind]);

        useImperativeHandle(ref, () => ({
            refreshListeners: () => {
                setDragListeners();
            },
        }));

        return null;
    },
);
