import { FiltersData } from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { Modal, ModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { SingleModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/Profile/utils';
import { MultipleModalsContext } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/MultipleModalsContext/MultipleModalsContext';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { CreateFormProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/forms/CreateForm';
import { OperationTypes, UserPlatformManagementEntityTypes } from '@cfra-nextgen-frontend/shared/src/utils/enums';
import { useContext, useMemo, useState } from 'react';

export type CreateModalProps = {
    modalTitle: string;
    entityType?: UserPlatformManagementEntityTypes;
    entityId?: number;
    onOperationSuccessSnackMessage: SingleModalProps['onOperationSuccessSnackMessage'];
    multipleResultsPopupId?: SingleModalProps['multipleResultsPopupId'];
    multipleResultsPopup?: SingleModalProps['multipleResultsPopup'];
    multipleResultsPopupTitle?: SingleModalProps['multipleResultsPopupTitle'];
    maxNumberOfItemsPerOneRequest?: SingleModalProps['maxNumberOfItemsPerOneRequest'];
    submitButtonName?: string;
    onBeforeCreateSuccess?: (data: any, formData?: RhFormData, filtersData?: FiltersData) => void;
    onCreationSuccessCallback?: CreateFormProps['onCreationSuccessCallback'];
    operationType?: OperationTypes;
    requestPath?: string;
    description?: ModalProps['description'];
    presetValues?: SingleModalProps['presetValues'];
    localFiltersMetadata?: SingleModalProps['localFiltersMetadata'];
    useScreenerDataForFilters?: CreateFormProps['useScreenerDataForFilters'];
    onSubmit?: CreateFormProps['onSubmit'];
};

export function CreateModal({ Form }: { Form?: any }) {
    return function CreateModalFn({
        modalTitle,
        entityType,
        entityId,
        onOperationSuccessSnackMessage,
        multipleResultsPopupId,
        multipleResultsPopup,
        multipleResultsPopupTitle,
        maxNumberOfItemsPerOneRequest,
        submitButtonName,
        onBeforeCreateSuccess,
        onCreationSuccessCallback,
        operationType = OperationTypes.CREATE,
        requestPath,
        description,
        presetValues,
        localFiltersMetadata,
        useScreenerDataForFilters,
        onSubmit,
    }: CreateModalProps) {
        if (!entityType) throw new Error('Entity type is required for CreateModal.');

        const { getModalState, multipleModalsStateDispatcher } = useContext(MultipleModalsContext);
        const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);

        const isOpenModalMemorized = useMemo(() => {
            return Boolean(getModalState(modalTitle)?.isOpen);
        }, [getModalState, modalTitle]);

        return (
            <Modal
                title={modalTitle}
                description={description || 'Required fields are marked by an asterisk *'}
                openModal={isOpenModalMemorized}
                callbackOnClose={() => setOpenConfirmationModal(true)}
                titleAndDescriptionContainerStyle={{
                    padding: '32px 30px 0px',
                }}
                titleStyle={{
                    display: 'flex',
                }}
                modalBoxStyles={{
                    maxWidth: '480px',
                    maxHeight: '85%',
                }}>
                <Form
                    analyticsCardName={modalTitle}
                    entityType={entityType}
                    entityId={entityId}
                    cancelButtonCallback={() =>
                        multipleModalsStateDispatcher({
                            type: 'complementModalsState',
                            newState: {
                                modals: {
                                    [modalTitle]: {
                                        isOpen: false,
                                    },
                                },
                            },
                        })
                    }
                    onOperationSuccessSnackMessage={onOperationSuccessSnackMessage}
                    openConfirmationModal={openConfirmationModal}
                    setOpenConfirmationModal={setOpenConfirmationModal}
                    submitButtonName={submitButtonName}
                    onBeforeCreateSuccess={onBeforeCreateSuccess}
                    onCreationSuccessCallback={onCreationSuccessCallback}
                    operationType={operationType}
                    requestPath={requestPath}
                    multipleResultsPopupId={multipleResultsPopupId}
                    multipleResultsPopup={multipleResultsPopup}
                    multipleResultsPopupTitle={multipleResultsPopupTitle}
                    maxNumberOfItemsPerOneRequest={maxNumberOfItemsPerOneRequest}
                    presetValues={presetValues}
                    localFiltersMetadata={localFiltersMetadata}
                    useScreenerDataForFilters={useScreenerDataForFilters}
                    onSubmit={(filtersData: FiltersData, formData: RhFormData, formDataState: Record<string, any>) => {
                        multipleModalsStateDispatcher({
                            type: 'complementModalsState',
                            newState: {
                                modals: {
                                    [modalTitle]: {
                                        isOpen: false,
                                    },
                                }
                            },
                        });
                        onSubmit?.(filtersData, formData, formDataState);
                    }}
                />
            </Modal>
        );
    }
}