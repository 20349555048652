import { AgGridSelectedRowsContext } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/AgGridSelectedRowsContext/AgGridSelectedRowsContext';
import { ColumnDef } from '@cfra-nextgen-frontend/shared/src/components/AgGrid/types';
import { getSnapshot, subscribe } from '@cfra-nextgen-frontend/shared/src/hooks/windowDimensions';
import { ValueTypes, formatValue } from '@cfra-nextgen-frontend/shared/src/utils/valuesFormatter';
import {
    BodyScrollEvent,
    ColDef,
    Column,
    ColumnApi,
    CsvExportParams,
    GridApi,
    IServerSideDatasource,
    ModelUpdatedEvent,
    RowClassRules,
    RowHeightParams,
    SortChangedEvent,
    ValueFormatterParams,
} from 'ag-grid-community';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'ag-grid-enterprise';
import { AgGridReact, AgGridReactProps } from 'ag-grid-react';
import { debounce, isEqual } from 'lodash';
import {
    forwardRef,
    useCallback,
    useContext,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    useSyncExternalStore,
} from 'react';
import { isTouchDevice } from '../../utils/touchScreen';
import './AgGrid.scss';
import { AgGridDragScroll, AgGridDragScrollRef } from './AgGridDragScroll';
import { AgGridHorizontalScrollArrows, AgGridHorizontalScrollArrowsRef } from './AgGridHorizontalScrollArrows';
import './paddings.scss';
import './unsortIconStyles.scss';
import './iconClasses.scss';
import { getClassNameValue, getDefaultColDef } from './utils';

export function getAgGridFormatter(formattingType: ValueTypes) {
    return function (params: ValueFormatterParams) {
        return String(
            formatValue({
                value: params.value,
                formattingType: formattingType,
            }),
        );
    };
}

export type ColumnAndGridApi = { columnApi: ColumnApi; api: GridApi };

function getColumnAndGridApiObject(eventObject: ColumnAndGridApi, refObject: ColumnAndGridApi) {
    if (eventObject.api && eventObject.columnApi) {
        return eventObject;
    }

    return refObject;
}

export type AgGridProps = {
    columnDefs: Array<ColumnDef>;
    rowsData?: Array<any>;
    getResizableMinWidthForColumn: (key: string) => number;
    updateCustomColumnsWidths?: (agGridObject: ColumnAndGridApi) => void;
    maxNumberOfRowsToDisplay?: number;
    maxGridContainerHeightPercentage?: number; // the value in percentage from window height [1;100], needed for cases when grid container is centered, for example if grid is placed inside modal window
    useSSRMode?: boolean;
    SSRDataSource?: IServerSideDatasource;
    SSRrowsToFetch?: number;
    rowMultiSelectWithClick?: boolean;
    rowSelection?: 'single' | 'multiple';
    getRowID?: (params: any) => string;
    suppressRowClickSelection?: boolean;
    useAutoHeight?: boolean;
    gridTheme?: string | Array<string>;
    defaultRowHeight?: number;
    headerHeight?: number;
    defaultMaxWidth?: number | null;
    unlimitedCalculatedHeight?: boolean;
    rowsGap?: number;
    lastRowAdditionalWidth?: number; // needed to not cut off shadow for last row;
    horizontalScrollbarAreaHeight?: number;
    customFlexibleColumns?: Array<string>;
    getRowHeight?: (params: RowHeightParams) => number | undefined | null;
    enableAutoSizeAllColumns?: boolean;
    onFirstDataRenderedRef?: React.MutableRefObject<() => void>;
    onModelUpdatedRef?: React.MutableRefObject<(event: ModelUpdatedEvent) => void>;
    onSortChangedRef?: React.MutableRefObject<(event: SortChangedEvent) => void>;
    fullHeightGrid?: boolean;
    embedFullWidthRows?: boolean;
    defaultCsvExportParams?: CsvExportParams;
    rowClassRules?: RowClassRules<any>;
    showSideHorizontalScrollIndicators?: boolean;
    useDragScroll?: boolean;
    useBrowserVerticalScroll?: boolean;
    onColumnMovedGetter?: (gridContainerRef: React.RefObject<HTMLDivElement>) => AgGridReactProps['onColumnMoved'];
    autoSizePadding?: number;
    tooltipShowDelay?: number;
};

export const defaultMinWidth = 120;

type SortState = {
    colId: string;
    sort: 'asc' | 'desc' | null;
};

export const AgGrid = forwardRef<AgGridReact | null, AgGridProps>((props, ref) => {
    const { onRowSelected, setGridRef } = useContext(AgGridSelectedRowsContext);
    const { height } = useSyncExternalStore(subscribe, getSnapshot);
    const [gridTopPosition, setGridTopPosition] = useState<number>();
    const gridRef = useRef<AgGridReact>(null);
    const gridContainerRef = useRef<HTMLDivElement>(null);
    const setDragListenersRef = useRef<AgGridDragScrollRef>(null);
    const horizontalScrollArrowsRef = useRef<AgGridHorizontalScrollArrowsRef>(null);
    const [gridApi, setGridApi] = useState<GridApi<any>>();
    const [gridHeight, setGridHeight] = useState<number>(0);

    useEffect(() => {
        const gridContainerRefCurrent = gridContainerRef.current;
        if (gridContainerRefCurrent) {
            const { top } = gridContainerRefCurrent.getBoundingClientRect();
            setGridTopPosition(top);
        }
    }, [gridContainerRef, props]);

    const {
        rowsData = [],
        getResizableMinWidthForColumn,
        updateCustomColumnsWidths,
        maxNumberOfRowsToDisplay = 20,
        maxGridContainerHeightPercentage,
        useSSRMode = false,
        useAutoHeight = false,
        gridTheme,
        defaultRowHeight = 46,
        headerHeight,
        defaultMaxWidth = 350,
        unlimitedCalculatedHeight,
        rowsGap,
        lastRowAdditionalWidth,
        horizontalScrollbarAreaHeight = 18,
        customFlexibleColumns,
        getRowHeight,
        enableAutoSizeAllColumns = true,
        onFirstDataRenderedRef,
        onModelUpdatedRef,
        onSortChangedRef,
        fullHeightGrid,
        embedFullWidthRows,
        defaultCsvExportParams,
        showSideHorizontalScrollIndicators,
        useDragScroll,
        useBrowserVerticalScroll,
        onColumnMovedGetter,
        autoSizePadding,
        tooltipShowDelay = 0,
    } = props;

    const calculateHeight = useCallback(() => {
        const windowHeight = height || 0;

        let rowsToDisplay: number = 0;

        if (useSSRMode) {
            rowsToDisplay = props.SSRrowsToFetch || 0;
        } else {
            rowsToDisplay = fullHeightGrid && gridApi ? gridApi?.getModel()?.getRowCount?.() : rowsData.length;
        }

        const numberOfRowsToShow = rowsToDisplay > maxNumberOfRowsToDisplay ? maxNumberOfRowsToDisplay : rowsToDisplay; // show vertical scroll if more than maxNumberOfRowsToDisplay rows

        const tableBodyHeight =
            horizontalScrollbarAreaHeight +
            numberOfRowsToShow * defaultRowHeight +
            (rowsGap || 0) * (numberOfRowsToShow - 1) +
            (lastRowAdditionalWidth || 0);

        const staticGridHeight = headerHeight ? tableBodyHeight + headerHeight : tableBodyHeight + defaultRowHeight;

        const _gridTopPosition = gridTopPosition || 0;

        if (maxGridContainerHeightPercentage) {
            const maxGridContainerHeight = (windowHeight * maxGridContainerHeightPercentage) / 100;
            const gridContainerSpaceAboveTheGrid = _gridTopPosition - (windowHeight - maxGridContainerHeight) / 2;
            const maxGridHeight = maxGridContainerHeight - gridContainerSpaceAboveTheGrid;

            return staticGridHeight < maxGridHeight ? staticGridHeight : maxGridHeight;
        }

        if (unlimitedCalculatedHeight) {
            return staticGridHeight;
        }

        return staticGridHeight < windowHeight - _gridTopPosition ? staticGridHeight : windowHeight - _gridTopPosition;
    }, [
        height,
        maxNumberOfRowsToDisplay,
        rowsData,
        useSSRMode,
        props.SSRrowsToFetch,
        defaultRowHeight,
        headerHeight,
        rowsGap,
        lastRowAdditionalWidth,
        horizontalScrollbarAreaHeight,
        gridTopPosition,
        maxGridContainerHeightPercentage,
        unlimitedCalculatedHeight,
        gridApi,
        fullHeightGrid,
    ]);

    const updateGridHeight = useCallback(() => {
        const calculatedHeight = calculateHeight();
        const minGridHeight =
            5 * defaultRowHeight + // 4 rows + header
            horizontalScrollbarAreaHeight;
        const gridHeight = calculatedHeight < minGridHeight ? minGridHeight : calculatedHeight;

        setGridHeight(gridHeight);
    }, [calculateHeight, defaultRowHeight, horizontalScrollbarAreaHeight]);

    useEffect(() => {
        updateGridHeight();
    }, [updateGridHeight]);

    useImperativeHandle(ref, () => gridRef.current!);

    const sortState = useMemo(
        () =>
            props.columnDefs
                .filter((colDef) => colDef.field && colDef.sort) // get only the columns that have sort enabled
                .map((colDef) => ({ colId: colDef.field!, sort: colDef.sort! })), // add sort to column state;
        [props.columnDefs],
    );

    const columnDefs = useMemo(
        () =>
            props.columnDefs?.map((obj) => ({
                minWidth: obj.checkboxSelection ? 20 : getResizableMinWidthForColumn(String(obj.headerName)),
                ...obj,
                sort: null, // remove sort from columnDefs, to avoid grid sort issues, it will be applied in onGridColumnsChanged
            })),
        [getResizableMinWidthForColumn, props.columnDefs],
    );

    useEffect(() => {
        // Check if gridRef is available and the grid API is ready
        if (!gridRef.current || !gridRef.current.api) {
            return;
        }

        // Retrieve current column definitions
        const currentColumnDefs = gridRef.current.api.getColumnDefs();

        // Do nothing the column definitions are already set and the columns set is the same
        if (
            columnDefs.length === 0 ||
            (currentColumnDefs &&
                currentColumnDefs.length > 0 &&
                isEqual(
                    columnDefs.map((columnDef) => columnDef.field),
                    (currentColumnDefs as Array<ColDef>)?.map((columnDef) => columnDef.field),
                ))
        ) {
            return;
        }

        gridRef.current.api.setColumnDefs(columnDefs);
    }, [columnDefs, gridRef.current]); // keep gridRef.current in dependencies to avoid empty grid on navigate to page

    useEffect(() => {
        if (!gridRef.current) return;
        gridRef.current?.columnApi?.applyColumnState({ state: sortState });
    }, [sortState, gridRef.current]); // keep gridRef.current in dependencies to avoid empty grid on navigate to page

    const firstRenderColumnDefs = useMemo(() => columnDefs, []); // keep here no dependencies, the firstRenderColumnDefs should update only first time

    const className = useMemo(() => getClassNameValue(gridTheme), [gridTheme]);

    // DefaultColDef sets props common to all Columns
    const defaultColDef = useMemo(
        (): ColDef => getDefaultColDef({ className, defaultMaxWidth }),
        [defaultMaxWidth, className],
    );

    function setMaxResizeWidthForAllColumnsTo(maxWidth: number, agGridObject?: ColumnAndGridApi) {
        const agGrid = agGridObject || gridRef.current;

        const columnDefsLocal: Array<ColDef> = agGrid?.api.getColumnDefs() as Array<ColDef>;
        if (!columnDefsLocal) {
            return;
        }
        agGrid?.api.setColumnDefs(columnDefsLocal.map((columnDef) => ({ ...columnDef, maxWidth: maxWidth })));
    }

    const setMinResizeWidthForAllColumns = useCallback(
        (agGridObject?: ColumnAndGridApi) => {
            const agGrid = agGridObject || gridRef.current;
            const columnDefsLocal = agGrid?.api.getColumnDefs();

            if (!columnDefsLocal) {
                return;
            }

            agGrid?.api.setColumnDefs(
                columnDefsLocal.map((columnDef) => ({
                    ...columnDef,
                    minWidth: (columnDef as ColDef).checkboxSelection ? 20 : getResizableMinWidthForColumn(String(columnDef.headerName)),
                })),
            );
        },
        [getResizableMinWidthForColumn],
    );

    const setInitialColumnDefsForAllColumns = useCallback(
        (keys: Array<string>, agGridObject?: ColumnAndGridApi) => {
            const agGrid = agGridObject || gridRef.current;

            const localColumnDefs: Array<ColumnDef> = agGrid?.api.getColumnDefs() as Array<ColumnDef>;

            if (!localColumnDefs) {
                return;
            }

            agGrid?.api.setColumnDefs(
                localColumnDefs.map((localColumnDef) => {
                    keys.forEach((key) => {
                        const colDefProp = columnDefs.filter((item) => item.field === localColumnDef.field)[0];

                        localColumnDef = {
                            ...localColumnDef,
                            [key]:
                                colDefProp && colDefProp.hasOwnProperty(key)
                                    ? colDefProp[key as keyof typeof colDefProp]
                                    : defaultColDef[key as keyof typeof defaultColDef],
                        };
                    });
                    return localColumnDef;
                }),
            );
        },
        [columnDefs, defaultColDef],
    );

    function calculateAllColumnsWidths() {
        return (
            gridRef.current?.columnApi
                .getColumns()
                ?.reduce((prevResult, current) => (prevResult += current.getActualWidth()), 0) || 0
        );
    }

    const autoSizeAllColumns = useCallback(
        (params: ColumnAndGridApi) => {
            const agGridObject = params || (gridRef.current as ColumnAndGridApi);

            if (!agGridObject || !columnDefs || columnDefs.length === 0 || !enableAutoSizeAllColumns) {
                return;
            }

            const keys = ['maxWidth', 'minWidth'];

            setInitialColumnDefsForAllColumns(keys, agGridObject);

            const allColumnIds: Array<Column> = [];
            agGridObject?.columnApi?.getColumns()?.forEach((column) => {
                const colDef = column.getColDef();

                if (
                    !customFlexibleColumns ||
                    !(colDef?.headerName && customFlexibleColumns.includes(colDef?.headerName))
                ) {
                    allColumnIds.push(column);
                    return;
                }
            });

            agGridObject?.columnApi?.autoSizeColumns(allColumnIds, true);

            const hasPinnedColumns = agGridObject.api
                .getColumnDefs()
                ?.map((colDef) => (colDef as ColumnDef).pinned)
                .some((x: any) => Boolean(x));

            if (!hasPinnedColumns) {
                const right = agGridObject?.api?.getHorizontalPixelRange()?.right;
                const actualWidthExceededViewableWidth = calculateAllColumnsWidths() > right;
                if (actualWidthExceededViewableWidth) {
                    agGridObject?.columnApi.autoSizeColumns(allColumnIds, false);
                }
            }

            if (updateCustomColumnsWidths) {
                updateCustomColumnsWidths(agGridObject);
            }

            setMaxResizeWidthForAllColumnsTo(9999, agGridObject); // set "unlimited" resize column width for all columns
            setMinResizeWidthForAllColumns(agGridObject);
        },
        [
            setInitialColumnDefsForAllColumns,
            setMinResizeWidthForAllColumns,
            updateCustomColumnsWidths,
            customFlexibleColumns,
            columnDefs,
            enableAutoSizeAllColumns,
        ],
    );

    const debouncedAutoSizeAllColumns = useMemo(() => {
        return debounce((event: ColumnAndGridApi) => {
            const agGrid = getColumnAndGridApiObject(event, gridRef.current as ColumnAndGridApi);

            const currentRowCount = agGrid.api.getDisplayedRowCount(); // Get the current number of displayed rows
            // don't autosize columns if no rows displayed
            if (
                currentRowCount === 0 || // should be useful for the client side rendering mode
                // for the SSR mode, we need to wait for the first portion of data to be loaded
                (currentRowCount === 1 && useSSRMode && props.SSRrowsToFetch && props.SSRrowsToFetch > 1)
            ) {
                return;
            }
            autoSizeAllColumns(agGrid);
        }, 500);
    }, [autoSizeAllColumns, props.SSRrowsToFetch, useSSRMode]);

    const onBodyScroll = useCallback((event: BodyScrollEvent): void => {
        if (showSideHorizontalScrollIndicators && event.direction === 'horizontal') {
            horizontalScrollArrowsRef.current?.refreshHorizontalScrollIndicators();
            horizontalScrollArrowsRef.current?.updateArrowsVerticalPositions();
        }
    }, [showSideHorizontalScrollIndicators]);

    const onColumnMoved = useMemo(() => {
        const _onColumnMoved = onColumnMovedGetter?.(gridContainerRef);
        return _onColumnMoved ? debounce(_onColumnMoved, 100) : undefined;
    }, [onColumnMovedGetter, gridContainerRef]);

    return (
        <div ref={gridContainerRef} style={{ width: '100%' }} className='cfra-ag-grid'>
            <div
                className={className}
                style={{
                    height: useAutoHeight ? undefined : `${gridHeight}px`,
                    width: '100%',
                }}>
                <AgGridReact
                    ref={gridRef}
                    {...(useSSRMode
                        ? {
                              rowModelType: 'serverSide',
                              serverSideDatasource: props.SSRDataSource,
                              cacheBlockSize: props.SSRrowsToFetch,
                              getRowId: props.getRowID,
                              rowMultiSelectWithClick: props.rowMultiSelectWithClick,
                          }
                        : { rowData: rowsData })}
                    columnDefs={firstRenderColumnDefs} // only for first render
                    suppressRowClickSelection={props.suppressRowClickSelection || false}
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true}
                    rowSelection={props.rowSelection || 'single'}
                    rowClassRules={props.rowClassRules}
                    getRowHeight={
                        getRowHeight ||
                        function (params) {
                            if (params.node.rowIndex === rowsData.length - 1) {
                                return defaultRowHeight + (lastRowAdditionalWidth || 0);
                            }
                            return defaultRowHeight + (rowsGap || 0);
                        }
                    }
                    getRowStyle={(params) => {
                        return {
                            maxHeight: getRowHeight?.(params) || defaultRowHeight,
                        };
                    }}
                    headerHeight={headerHeight || defaultRowHeight}
                    suppressPropertyNamesCheck={true} // This is not ideal, but see: https://github.com/ag-grid/ag-grid/issues/2320
                    onModelUpdated={(event) => {
                        if (fullHeightGrid) {
                            updateGridHeight();
                        }

                        if (useSSRMode) {
                            // Get the current number of displayed rows
                            const currentRowCount = event.api.getDisplayedRowCount();
                            // trigger autosize only for the first portion of data
                            if (props.SSRrowsToFetch && currentRowCount === props.SSRrowsToFetch) {
                                debouncedAutoSizeAllColumns(event);
                            }
                        }

                        setDragListenersRef.current?.refreshListeners();

                        if (showSideHorizontalScrollIndicators) {
                            horizontalScrollArrowsRef.current?.refreshHorizontalScrollIndicators();
                            horizontalScrollArrowsRef.current?.updateArrowsVerticalPositions();
                        }

                        if (!onModelUpdatedRef?.current) {
                            return;
                        }

                        // need at least 100 timeout to have for grid rows rendered
                        setTimeout(() => onModelUpdatedRef?.current?.(event), 100);
                    }}
                    onGridReady={(event) => {
                        debouncedAutoSizeAllColumns(event);

                        if (fullHeightGrid) {
                            setGridApi(event.api);
                        }

                        if (!setGridRef) return;
                        setGridRef(gridRef.current);
                    }}
                    onGridSizeChanged={(event) => {
                        debouncedAutoSizeAllColumns(event);
                    }}
                    onRowDataUpdated={(event) => {
                        debouncedAutoSizeAllColumns(event);
                    }}
                    onGridColumnsChanged={(event) => {
                        debouncedAutoSizeAllColumns(event);
                    }}
                    onFirstDataRendered={(event) => {
                        debouncedAutoSizeAllColumns(event);
                        onFirstDataRenderedRef?.current?.();

                        if (showSideHorizontalScrollIndicators) {
                            horizontalScrollArrowsRef.current?.refreshHorizontalScrollIndicators();
                            horizontalScrollArrowsRef.current?.updateArrowsVerticalPositions();
                        }
                    }}
                    onColumnMoved={onColumnMoved}
                    onBodyScroll={onBodyScroll}
                    onSelectionChanged={onRowSelected || (() => {})}
                    tooltipShowDelay={tooltipShowDelay}
                    domLayout={useAutoHeight ? 'autoHeight' : 'normal'}
                    gridOptions={{
                        getContextMenuItems: function (params) {
                            var defaultItems = params.defaultItems;
                            if (!defaultItems) {
                                return [];
                            }
                            var result = defaultItems.filter((item) => item !== 'export'); // hide export option from context menu
                            return result;
                        },
                    }}
                    suppressDragLeaveHidesColumns // disable remove column by drag and drop it out of grid
                    suppressMultiSort
                    onSortChanged={(event) => {
                        onSortChangedRef?.current(event);
                    }}
                    {...(typeof embedFullWidthRows === 'boolean' ? { embedFullWidthRows: embedFullWidthRows } : {})}
                    defaultCsvExportParams={defaultCsvExportParams}
                    autoSizePadding={autoSizePadding}
                    onColumnResized={() => {
                        if (showSideHorizontalScrollIndicators) {
                            horizontalScrollArrowsRef.current?.refreshHorizontalScrollIndicators();
                        }
                    }}
                />
                {useDragScroll && !isTouchDevice && (
                    <AgGridDragScroll
                        gridRef={gridRef}
                        gridContainerRef={gridContainerRef}
                        ref={setDragListenersRef}
                        useBrowserVerticalScroll={useBrowserVerticalScroll}
                    />
                )}
                {showSideHorizontalScrollIndicators && (
                    <AgGridHorizontalScrollArrows
                        gridRef={gridRef}
                        gridContainerRef={gridContainerRef}
                        ref={horizontalScrollArrowsRef}
                    />
                )}
            </div>
        </div>
    );
});
