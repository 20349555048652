import {
    applyCustomFilterValueFormatter,
    getFieldMetaBySourceField,
    getValueByFieldKey,
    updateMetadataUrlLinkParams,
} from '@cfra-nextgen-frontend/shared/src/components/Form/shared/utils';
import {
    Components,
    FilterMetadata,
    FiltersData,
    FiltersMetadata,
} from '@cfra-nextgen-frontend/shared/src/components/Form/types/filters';
import { ModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/Modal';
import { CreateModalProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/CreateModal';
import { InformationPopup } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/InformationPopup';
import { ScreenerHomeProps } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/ScreenerHome';
import { SendAlertWrapper } from '@cfra-nextgen-frontend/shared/src/components/Screener/components/SendAlertWrapper';
import { RhFormData } from '@cfra-nextgen-frontend/shared/src/components/Screener/filtersModal/utils';
import { combineIntoFormElementName } from '@cfra-nextgen-frontend/shared/src/components/Screener/screenerFormElements/shared';
import { DataItem, ScreenerData } from '@cfra-nextgen-frontend/shared/src/components/Screener/types/screener';
import {
    OperationTypes,
    RequestTypes,
    UserPlatformManagementEntityTypes,
} from '@cfra-nextgen-frontend/shared/src/utils';
import { SearchByParams } from '@cfra-nextgen-frontend/shared/src/utils/api';
import _ from 'lodash';
import React from 'react';
import { processAuditTrailRecords } from './AuditTrailUtils';
import { getEditButtonVariant2 } from '../EditButtonsV2';
import { UseFormSetValue, FieldValues } from 'react-hook-form';
import { AgGridRowClassRules } from '../../utils/enum';
import { Box, Chip } from '@mui/material';
import { StyledFormLabel } from '../../../Form/shared/StyledFormLabel';
import { AddForm } from '../../forms/AddForm';
import { getDividerString } from '@cfra-nextgen-frontend/shared/src/utils/strings';
import { ColumnDef } from '../../../AgGrid/types';

function _getFiltersMetadataByViewType(
    screenerData: ScreenerData,
    originalFiltersMetadata: FiltersMetadata,
    isStatic: boolean,
) {
    const resultFiltersMetadata = _.cloneDeep(originalFiltersMetadata);
    const defaultValues: Record<string, any> = {};

    if (isStatic) {
        Object.keys(resultFiltersMetadata).forEach((key) => {
            resultFiltersMetadata[key].component = Components.StaticText;
        });
    }

    for (const key in resultFiltersMetadata) {
        const { item_metadata, source_field } = resultFiltersMetadata[key];

        //update url_link params
        if (item_metadata && item_metadata?.['url_link']?.['params_source_field']) {
            resultFiltersMetadata[key]['item_metadata'] = updateMetadataUrlLinkParams(item_metadata, {
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                metadata: screenerData._metadata,
            });
        }

        //fetch or derive default value
        if (item_metadata && item_metadata?.source_fields && item_metadata?.value_formatter) {
            resultFiltersMetadata[key]['default_value'] = applyCustomFilterValueFormatter({
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                metadata: screenerData._metadata,
                fieldMetadata: resultFiltersMetadata[key].item_metadata,
                returnRawCalculatedValue: isStatic,
            });
        } else if (typeof source_field === 'string') {
            const fieldMetaList = getFieldMetaBySourceField(screenerData._metadata, source_field);

            if (fieldMetaList.length === 0) {
                throw new Error(`Field meta not found for source_field: ${source_field}`);
            }

            const fieldPath = Object.keys(fieldMetaList[0])[0];
            const defaultValue = getValueByFieldKey({
                rowData: screenerData.results.data[0],
                viewdata: screenerData._viewdata,
                fieldPath,
            });
            resultFiltersMetadata[key]['default_value'] = defaultValue;
           
            let defaultValues: any[] = [];
            for (let i = 0; i < screenerData.results.data.length; i++) {
                const val = getValueByFieldKey({
                    rowData: screenerData.results.data[i],
                    viewdata: screenerData._viewdata,
                    fieldPath,
                });
                if (val !== undefined || val !== null) {
                    if (Array.isArray(val)) {
                        defaultValues = defaultValues.concat(val);
                    } else {
                        defaultValues.push(val);
                    }
                }
            }

            resultFiltersMetadata[key]['default_values'] = defaultValues.length > 0 ? defaultValues : null;
        }

        const formKey = combineIntoFormElementName({
            componentName: isStatic ? Components.StaticText : resultFiltersMetadata[key].component,
            filterMetadataKey: key,
        });

        defaultValues[formKey] = resultFiltersMetadata[key]['default_value'];
    }

    return { resultFiltersMetadata, defaultValues };
}

export function getFiltersMetadataByViewType({
    screenerData,
    filtersMetadata,
}: {
    screenerData: ScreenerData;
    filtersMetadata: FiltersMetadata;
}) {
    const { resultFiltersMetadata: nonStaticFiltersMetadata, defaultValues: nonStaticDefaultValues } =
        _getFiltersMetadataByViewType(screenerData, filtersMetadata, false);
    const { resultFiltersMetadata: staticFiltersMetadata, defaultValues: staticDefaultValues } =
        _getFiltersMetadataByViewType(screenerData, filtersMetadata, true);

    return {
        filtersMetadata: nonStaticFiltersMetadata,
        defaultValues: nonStaticDefaultValues,
        staticFiltersMetadata,
        staticDefaultValues,
    };
}

export type SingleModalProps = {
    onOperationSuccessSnackMessage: string;
    entityType: UserPlatformManagementEntityTypes;
    submitButtonName: string;
    title: string;
    buttonText?: string;
    operationType?: OperationTypes;
    requestPath?: string;
    description?: ModalProps['description'];
    onBeforeCreateSuccess?: (data: any, formData?: RhFormData, filtersData?: FiltersData) => void;
    multipleResultsPopupId?: string;
    multipleResultsPopup?: React.ReactNode;
    multipleResultsPopupTitle?: string;
    maxNumberOfItemsPerOneRequest?: number;
    presetValues?: Record<string, any>;
    localFiltersMetadata?: Record<string, Partial<FilterMetadata>>;
    doNotShowModalOpenButtonOnScreenerCard?: boolean;
    useScreenerDataForFilters?: boolean;
};

export type RowLevelFiltersConfig = {
    requestParams?: SearchByParams;
    requestDefaultValue?: Record<string, any>;
};

export type ModalWrapperProps = { ModalComponent: React.FC<CreateModalProps> } & CreateModalProps;

export type Modal = {
    props: SingleModalProps;
    ModalWrapper?: React.FC<ModalWrapperProps>;
    formComponent?: any;
};

export type EntityAssociatedItem = {
    screenerProps: Partial<ScreenerHomeProps>;
    modals?: Array<Modal>;
};

function BulkUploadDescription() {
    const file = 'Bulk_Upload_Template.xlsx';
    const templateLink = (
        <a href={`/files/${file}`} download={file}>
            template.xlsx
        </a>
    );

    return (
        <>
            Input list of users for bulk upload into the {templateLink}. All fields for a user must be populated in
            order for user upload.
        </>
    );
}

export function getEntityAssociatedItems(
    entityType: UserPlatformManagementEntityTypes,
    entityId: number,
): Array<EntityAssociatedItem> {
    if (entityType === UserPlatformManagementEntityTypes.Account) {
        return [
            {
                screenerProps: {
                    cardName: 'Groups',
                    screenerRequestParams: {
                        path: 'group',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Create Group',
                            submitButtonName: 'Create Group',
                            onOperationSuccessSnackMessage: 'Successfully created group {name}',
                            entityType: UserPlatformManagementEntityTypes.Group,
                            onBeforeCreateSuccess: () => {},
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.group.company_name',
                                })]: '{account_name}',
                                [combineIntoFormElementName({
                                    componentName: Components.Checkbox,
                                    filterMetadataKey: 'user_management.group.is_active',
                                })]: { value: true,  isDirty: true }
                            },
                        },
                    },
                    {
                        props: {
                            title: 'Edit Group',
                            submitButtonName: 'Edit Group',
                            onOperationSuccessSnackMessage: 'Successfully updated group {name}',
                            entityType: UserPlatformManagementEntityTypes.Group,
                        },
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Packages',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Users',
                    enableTopLeftActionPanel: true,
                    enableTopRightActionPanel: true,
                    searchPlaceholder: 'Search for User',
                    screenerRequestParams: {
                        path: 'user',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.account_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Create User',
                            submitButtonName: 'Create User',
                            onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                        },
                        ModalWrapper: SendAlertWrapper,
                    },
                    {
                        props: {
                            title: 'Bulk User Create for {profileTitle}',
                            buttonText: 'Bulk User Create',
                            submitButtonName: 'Create',
                            onOperationSuccessSnackMessage: 'Successfully created {count} user{countBasedEnding}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            operationType: OperationTypes.BULK_CREATE,
                            requestPath: 'user/_batch',
                            description: <BulkUploadDescription />,
                            multipleResultsPopupId: 'Bulk User Create Failed Results Popup',
                            multipleResultsPopup: (
                                <InformationPopup
                                    modalId={'Bulk User Create Failed Results Popup'}
                                    analyticsCardName={'Bulk User Create Failed Results Popup'}
                                />
                            ),
                            multipleResultsPopupTitle:
                                'The following {numberOfItemsFailedToOperate} users were not created:',
                            maxNumberOfItemsPerOneRequest: 20,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                        },
                        ModalWrapper: SendAlertWrapper,
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Account Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['account'] },
                                    'user_management.audit.op': { values: ['UPDATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
        ];
    } else if (entityType === UserPlatformManagementEntityTypes.User) {
        return [
            {
                screenerProps: {
                    cardName: 'Groups',
                    screenerRequestParams: {
                        path: 'group',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_group.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Edit Groups',
                            submitButtonName: 'Save',
                            entityType: UserPlatformManagementEntityTypes.User_Group,
                            onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                            onBeforeCreateSuccess: () => {},
                            operationType: OperationTypes.BULK_EDIT,
                            useScreenerDataForFilters: true
                        },
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Packages',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_group.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Notifications',
                    enableTopLeftActionPanel: true,
                    screenerRequestParams: {
                        path: 'user_alert_subscription',
                        view: 'user_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.user_alert_subscription.user_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user_alert_subscription',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'User Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'user_group',
                        requestBody: {
                            filters: {
                                values: {
                                    $or: [
                                        {
                                            $and: [
                                                {
                                                    $or: [
                                                        {
                                                            'user_management.audit.record->>user_id': {
                                                                values: [entityId],
                                                            },
                                                        },
                                                        {
                                                            'user_management.audit.old_record->>user_id': {
                                                                values: [entityId],
                                                            },
                                                        },
                                                    ],
                                                },
                                                {
                                                    'user_management.audit.table_name': {
                                                        values: ['user_group'],
                                                    },
                                                },
                                                {
                                                    'user_management.audit.op': {
                                                        values: ['CREATE', 'UPDATE', 'DELETE'],
                                                    },
                                                },
                                            ],
                                        },
                                        {
                                            'user_management.audit.table_name': {
                                                values: ['user'],
                                            },
                                            'user_management.audit.op': {
                                                values: ['UPDATE'],
                                            },
                                            'user_management.audit.record->>id': {
                                                values: [entityId],
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
        ];
    } else if (entityType === UserPlatformManagementEntityTypes.Group) {
        return [
            {
                screenerProps: {
                    cardName: 'Packages',
                    enableTopLeftActionPanel: true,
                    enableTopRightActionPanel: true,
                    searchPlaceholder: 'Search for Packages',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'account_group',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Edit Packages',
                            submitButtonName: 'Save',
                            entityType: UserPlatformManagementEntityTypes.Group,
                            onOperationSuccessSnackMessage: 'Successfully created package',
                            onBeforeCreateSuccess: () => {},
                            operationType: OperationTypes.EDIT,
                        },
                    },
                ],
            },
            {
                screenerProps: {
                    cardName: 'Users',
                    enableTopLeftActionPanel: true,
                    enableTopRightActionPanel: true,
                    searchPlaceholder: 'Search for User',
                    screenerRequestParams: {
                        path: 'user',
                        view: 'account_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group.id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
                modals: [
                    {
                        props: {
                            title: 'Create User',
                            submitButtonName: 'Create User',
                            onOperationSuccessSnackMessage: 'Successfully created user {first_name} {last_name}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',    //value that must be pre-set but not appear in the request when saving.
                                [combineIntoFormElementName({
                                    componentName: Components.AutocompleteOutsideChips,
                                    filterMetadataKey: 'user_management.user.user_group',
                                })]: { value: '{group_name}', isArray: true, isDirty: true }  //value that must be pre-set and appear in the request when saving 
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                        },
                        ModalWrapper: SendAlertWrapper
                    },
                    {
                        props: {
                            title: 'Bulk User Create for {profileTitle}',
                            buttonText: 'Bulk User Create',
                            submitButtonName: 'Create',
                            onOperationSuccessSnackMessage: 'Successfully created {count} user{countBasedEnding}',
                            entityType: UserPlatformManagementEntityTypes.User,
                            operationType: OperationTypes.BULK_CREATE,
                            requestPath: 'user/_batch',
                            description: <BulkUploadDescription />,
                            multipleResultsPopupId: 'Bulk User Create Failed Results Popup',
                            multipleResultsPopup: (
                                <InformationPopup
                                    modalId={'Bulk User Create Failed Results Popup'}
                                    analyticsCardName={'Bulk User Create Failed Results Popup'}
                                />
                            ),
                            multipleResultsPopupTitle:
                                'The following {numberOfItemsFailedToOperate} users were not created:',
                            maxNumberOfItemsPerOneRequest: 20,
                            presetValues: {
                                [combineIntoFormElementName({
                                    componentName: Components.AutoCompleteFreeSolo,
                                    filterMetadataKey: 'user_management.user.company_name',
                                })]: '{account_name}',
                                [combineIntoFormElementName({
                                    componentName: Components.AutocompleteOutsideChips,
                                    filterMetadataKey: 'user_management.user.user_group',
                                })]:  { value: '{group_name}', isArray: true, isDirty: true }
                            },
                            localFiltersMetadata: {
                                'user_management.user.company_name': {
                                    disabled: true,
                                },
                            },
                        },
                        ModalWrapper: SendAlertWrapper,
                    },
                ],
            },
        ];
    } else if (entityType === UserPlatformManagementEntityTypes.Package) {
        return [
            {
                screenerProps: {
                    cardName: 'Entitlements',
                    entityType: UserPlatformManagementEntityTypes.Package_Entitlement,
                    screenerRequestParams: {
                        path: 'package_entitlement',
                        overrideRequestPath: 'package_entitlement',
                        view: 'package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.package_entitlement.package_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    enableTopRightActionPanel: true,
                    searchPlaceholder: 'Search for Entitlement',
                    enableTopLeftActionPanel: true,
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'package_entitlement',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                    showSelectionOnEdit: true,
                    EditButtonsVariant: getEditButtonVariant2({ addButtontitle: 'Add Entitlements' }),
                    operationType: OperationTypes.BULK_EDIT,
                    hardRefreshOnUpdate: true,
                    processDeleteAction: (
                        setValue: UseFormSetValue<FieldValues>,
                        selectedIds: string[], 
                        rowLevelFiltersFormKeys?: Record<string, Record<string, string>>
                    ) => {
                        selectedIds.forEach((id) => {
                            const formFilterName = rowLevelFiltersFormKeys?.[id]?.["user_management.lookup_entitlement.is_active"];
                            if (formFilterName)
                                setValue(formFilterName, false, { shouldDirty: true });
                        })
                    },
                    getRowClassRules: (ruleType: AgGridRowClassRules, selectedIds: string[], rowData: any) => {
                        switch (ruleType) {
                            case AgGridRowClassRules.ToBeDeleted:
                                return selectedIds.indexOf(rowData.id) > -1;
                            case AgGridRowClassRules.ToBeAdded:
                                return rowData.newRow;
                            default:
                                return false
                        }
                    },
                    getConfirmationModalContent: (rowsData?: DataItem[], rowsToBeDeleted?: string[]) => {
                        const pillsToBeDeleted: any[] = []
                        if (rowsToBeDeleted?.length) {
                            rowsToBeDeleted.forEach((id) => {
                                const rowData = rowsData?.find(p => p.id?.toString() == id.toString())
                                pillsToBeDeleted.push({
                                    key: rowData?.id,
                                    value: rowData?.lookup_entitlement?.name
                                })
                            })
                        }

                        let pillsToBeAdded: any[] = [];
                        const newRows = rowsData?.filter(p => p.newRow);
                        if (newRows?.length) {
                            pillsToBeAdded = newRows.map(p => ({ key: p?.id, value: p?.lookup_entitlement?.name}));
                        }

                        return (<Box sx={{ width: '100%', paddingTop: '30px' }}>
                            <StyledFormLabel sx={{ lineHeight: 1.2, paddingTop: '10px', paddingBottom: '10px' }}>
                                {'Added Entitlements'}
                            </StyledFormLabel>
                            {
                            pillsToBeAdded.length > 0 ? 
                                pillsToBeAdded
                                    .map(({ key, value }: any) => (
                                        <Chip sx={{ margin: '5px' }} key={key} label={value} />
                                    )) : 
                                <div style={{ marginLeft: '5px' }}>-</div>
                            }
                            <StyledFormLabel sx={{ lineHeight: 1.2, paddingTop: '30px', paddingBottom: '10px' }}>
                                {'Removed Entitlements'}
                            </StyledFormLabel>
                            {
                            pillsToBeDeleted.length > 0 ? 
                                pillsToBeDeleted
                                    .map(({ key, value }: any) => (
                                        <Chip sx={{ margin: '5px' }} key={key} label={value} />
                                    )): 
                                <div style={{ marginLeft: '5px' }}>-</div>
                            }
                        </Box>)
                    },
                    getFormDataState: (rowsData?: DataItem[], additonData?: {
                        rowsToBeAdded?: any[],
                        externalFormData?: { filtersData: FiltersData, formData: RhFormData, formDataState: Record<string, any> }
                    }, formData?: RhFormData) => {
                        
                        const idToDelete: any[] = [];
                        if (formData) {
                            Object.keys(formData || {}).forEach((formKey) => {
                                if (formData?.[formKey] === false) {
                                    const keys = formKey?.split(getDividerString('DIVIDER'));
                                    idToDelete.push(Number.parseInt(keys[keys.length - 1]));
                                }
                            })
                        }

                        let isRowsDataUpdated = false;
                        let updatedRowsData = rowsData?.map(data => {
                            if (idToDelete.includes(data.id) && data.lookup_entitlement.is_active) {
                                data.lookup_entitlement.is_active = false;
                                isRowsDataUpdated = true;
                            }
                            return data;
                        }) || [];

                        if (additonData?.rowsToBeAdded?.length) {
                            updatedRowsData = updatedRowsData.filter(r => !r.newRow); // first only gets which is not newly added before.
                            const lastRow = rowsData?.sort(p => p.id)[rowsData?.length - 1];
                            let id: number = (lastRow?.id || 9999);
                            additonData.rowsToBeAdded.forEach((row) => {
                                const entitlementData: any = additonData?.externalFormData?.filtersData?.data["user_management.package.package_entitlement"].items.find(
                                    (item) => item.key === row.key
                                );
                                const productData: any = additonData?.externalFormData?.filtersData?.data["user_management.lookup_product.value"].items.find(
                                    (item) => item.key === (entitlementData as any).product_lid
                                );

                                updatedRowsData.push({
                                    id,
                                    package_id: lastRow?.package_id,
                                    lookup_entitlement: {
                                        is_active: true,
                                        name: entitlementData.value,
                                        value: entitlementData.lookup_entitlement_value,
                                        description: entitlementData.description,
                                        lookup_id: row.key,
                                        lookup_product: {
                                            value: productData?.value
                                        }
                                    },
                                    newRow: true // keep this flag to use at other places
                                });
                                id++;
                            })
                            
                            const existingDataKeys = rowsData?.filter(rowData => rowData.newRow).map(r => r.lookup_entitlement.lookup_id);
                            isRowsDataUpdated = existingDataKeys?.length ? 
                                (existingDataKeys.length !== additonData.rowsToBeAdded.length || existingDataKeys.some(key => !additonData.rowsToBeAdded?.map(p => p.key).includes(key))) : 
                                true 
                            
                        }
                        const formKey = combineIntoFormElementName({
                            componentName: Components.AutocompleteOutsideChips,
                            filterMetadataKey: "user_management.package.package_entitlement"
                        });
                        return {
                            state: {
                                [formKey]: {
                                    [RequestTypes.DELETE]: idToDelete.map(p => ({ key: p })),
                                    [RequestTypes.POST]: updatedRowsData.filter(p => p.newRow).map(p => ({ key: p.lookup_entitlement.lookup_id, description: p.lookup_entitlement.description }))
                                }
                            },
                            deletion: idToDelete,
                            addition: additonData?.rowsToBeAdded,
                            isRowsDataUpdated,
                            updatedRowsData: updatedRowsData
                        }
                        
                    },
                    processAddAction: (rowsData, externalFormData) => {
                        const dataToAdd: any[] = [];
                        if (externalFormData.formDataState) {
                            Object.keys(externalFormData.formDataState).forEach((filter) => {
                                const filterObj = externalFormData.formDataState?.[filter];
                                if (filterObj[RequestTypes.POST]) {
                                    filterObj[RequestTypes.POST].forEach((filterItem: { key: number }) => {
                                        const filterDataObj: any = externalFormData.filtersData?.data["user_management.package.package_entitlement"].items.find(
                                            (item) => item.key === filterItem.key
                                        );
                                        if (filterDataObj) {
                                            dataToAdd.push({
                                                'key': filterItem.key,
                                                'description': filterDataObj.description
                                            });
                                        }
                                    });
                                }
                            });
                        }
                        const existingDataKeys = rowsData.filter(rowData => rowData.newRow).map(r => r.lookup_entitlement.lookup_id);

                        return { 
                            dataToAdd, 
                            hasUpdate: existingDataKeys?.length ? 
                                (existingDataKeys.length !== dataToAdd.length || existingDataKeys.some(key => !dataToAdd.map(p => p.key).includes(key))) : true 
                        }
                    },
                    getDefaultView: (rowsData: DataItem[], formData: RhFormData) => {
                        Object.keys(formData).forEach(key => formData[key] = true);
                        return {
                            defaultRowsData: rowsData.filter(p => !p.newRow).map(p => {
                                p.lookup_entitlement.is_active = true;
                                return p;
                            }),
                            defaultFormData: formData
                        }
                    },
                    getColumnOrderderedInEditMode: (columnDefs: ColumnDef[]) => {
                        columnDefs.splice(2, 0, columnDefs[columnDefs.length - 1]);
                        return columnDefs.slice(0, -1);
                    }
                },
                modals: [
                    {
                        props: {
                            title: 'Add Entitlements',
                            submitButtonName: 'Add',
                            entityType: UserPlatformManagementEntityTypes.Package_Entitlement,
                            onOperationSuccessSnackMessage: '',
                            onBeforeCreateSuccess: () => {},
                            operationType: OperationTypes.BULK_CREATE,
                            doNotShowModalOpenButtonOnScreenerCard: true,
                            useScreenerDataForFilters: true
                        },
                        formComponent: (props: any) => <AddForm {...props}/>
                    },
                ]
            },
            {
                screenerProps: {
                    cardName: 'Groups',
                    enableTopRightActionPanel: true,
                    enableTopLeftActionPanel: true,
                    searchPlaceholder: 'Search for Group',
                    screenerRequestParams: {
                        path: 'group_package',
                        view: 'package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group_package.package_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Users',
                    enableTopRightActionPanel: true,
                    enableTopLeftActionPanel: false,
                    searchPlaceholder: 'Search for User',
                    screenerRequestParams: {
                        path: 'user',
                        view: 'package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.group_package.package_id': {
                                        values: [entityId],
                                    },
                                },
                            },
                        },
                    },
                    rowLevelFiltersConfig: {
                        requestParams: {
                            path: 'user',
                            type: 'bulk_edit',
                        },
                        requestDefaultValue: {
                            id: '{row_id}',
                            values: '{action_value}',
                        },
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Package Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'package_profile',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['package'] },
                                    'user_management.audit.op': { values: ['UPDATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Added Entitlement Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'package_entitlement',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.record->>package_id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['package_entitlement'] },
                                    'user_management.audit.op': { values: ['CREATE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            },
            {
                screenerProps: {
                    cardName: 'Removed Entitlement Audit Trail',
                    screenerRequestParams: {
                        path: 'audit',
                        view: 'package_entitlement_old',
                        requestBody: {
                            filters: {
                                values: {
                                    'user_management.audit.old_record->>package_id': { values: [entityId] },
                                    'user_management.audit.table_name': { values: ['package_entitlement'] },
                                    'user_management.audit.op': { values: ['DELETE'] },
                                },
                            },
                        },
                        processResponse: processAuditTrailRecords,
                    },
                },
            }
        ];
    }
    throw new Error(`getEntityAssociatedItems exception. Pointed invalid entity type - '${entityType}'`);
}

export function getEntityFilterReqParams(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
): SearchByParams {
    if (entityType === UserPlatformManagementEntityTypes.User && operationType === OperationTypes.CREATE) {
        return {
            path: entityType,
            type: operationType,
            includeData: true,
            requestBody: {
                filters: {
                    values: {
                        'user_management.account.company_name': {
                            values: ['-1'],
                        },
                    },
                },
            },
        };
    }
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
        return {
            path: entityType,
            type: operationType,
            includeData: true,
            requestBody: {
                filters: {
                    values: {
                        'user_management.group.account_id': {
                            values: ['{filterPreRequestParamValues.user_group.group.account.id}'],
                        },
                    },
                },
            },
        };
    }
    if (entityType === UserPlatformManagementEntityTypes.Package_Entitlement && operationType === OperationTypes.BULK_CREATE) {
        return {
            path: entityType,
            type: operationType,
            includeData: true,
            requestBody: {
                filters: {
                    values: {
                        'user_management.lookup_product.value': {
                            values: ['-1'],
                        },
                    },
                },
            },
        };
    }
    if (
        [
            UserPlatformManagementEntityTypes.User,
            UserPlatformManagementEntityTypes.Account,
            UserPlatformManagementEntityTypes.Package,
            UserPlatformManagementEntityTypes.Package_Entitlement,
            UserPlatformManagementEntityTypes.Group,
        ].includes(entityType)
    ) {
        return { path: entityType, type: operationType, includeData: true };
    }

    throw new Error(`getEntityFilterReqParams exception. Pointed invalid entity type - '${entityType}'`);
}

export function getFilterPreRequestParams(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    entityId?: number,
): SearchByParams {
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
        return {
            path: 'user',
            view: 'default',
            requestBody: {
                filters: {
                    values: {
                        'user_management.user.id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    } else if (entityType === UserPlatformManagementEntityTypes.Package_Entitlement && operationType === OperationTypes.BULK_CREATE) {
        return {
            path: 'package_entitlement',
            view: 'package_profile',
            requestBody: {
                filters: {
                    values: {
                        'user_management.package_entitlement.package_id': {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    return {};
}

export function modifyFormRequestBody(
    requestBody: any,
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    entityId?: number,
): any {
    if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType == OperationTypes.BULK_EDIT) {
        if (requestBody[entityType]?.length > 0) {
            requestBody[entityType].forEach((req: any) => {
                req['user_id'] = req['user_id'] || entityId;
            });
        }
        return requestBody[entityType] || requestBody;
    }
    if (entityType === UserPlatformManagementEntityTypes.Package_Entitlement && operationType == OperationTypes.BULK_EDIT) {
        if (requestBody[entityType]?.length > 0) {
            requestBody[entityType].forEach((req: any) => {
                req['package_id'] = req['package_id'] || entityId;
            });
        }
        return requestBody[entityType] || requestBody;
    }
    return requestBody;
}

export function getModifyDeleteRequestFunction(
    entityType: UserPlatformManagementEntityTypes,
    operationType: OperationTypes,
    screenerQueryResult: any,
) {
    return function (requestPath: string, deleteRequest: any) {
        if (entityType === UserPlatformManagementEntityTypes.User_Group && operationType === OperationTypes.BULK_EDIT) {
            const data = screenerQueryResult.data.results.data[0];
            const deleteEntity = data[UserPlatformManagementEntityTypes.User_Group]?.find(
                (p: any) => p.group.id === deleteRequest.key,
            );
            return {
                searchByParams: {
                    path: `${requestPath}/${deleteEntity.id}`,
                    config: {
                        enabled: true,
                    },
                    noErrorOnNoKeyValuePairs: true,
                },
                requestType: RequestTypes.DELETE,
                invalidate: false,
            };
        } else if (entityType === UserPlatformManagementEntityTypes.Package_Entitlement && operationType === OperationTypes.BULK_EDIT) {
            const data = screenerQueryResult.data.results.data;
            const deleteEntity = data?.find(
                (p: any) => p.id === deleteRequest.key,
            );
            return {
                searchByParams: {
                    path: `${requestPath}/${deleteEntity.id}`,
                    config: {
                        enabled: true,
                    },
                    noErrorOnNoKeyValuePairs: true,
                },
                requestType: RequestTypes.DELETE,
                invalidate: false,
            };
        }
    };
}

export function getEntityScreenerReqParam(
    entityType: UserPlatformManagementEntityTypes,
    entityId: number,
): SearchByParams {
    const availableEntities: Record<string, Array<string>> = {
        [UserPlatformManagementEntityTypes.Account]: ['account', 'account_profile', 'user_management.account.id'],
        [UserPlatformManagementEntityTypes.User]: ['user', 'user_profile', 'user_management.user.id'],
        [UserPlatformManagementEntityTypes.Group]: ['group', 'account_group', 'user_management.group.id'],
        [UserPlatformManagementEntityTypes.Package]: ['package', 'package_profile', 'user_management.package.id']
    };

    const parameters = availableEntities[entityType];
    if (parameters && parameters.length > 0) {
        return {
            path: parameters[0],
            view: parameters[1],
            requestBody: {
                filters: {
                    values: {
                        [parameters[2]]: {
                            values: [entityId],
                        },
                    },
                },
            },
        };
    }
    throw new Error(`getEntityScreenerReqParam exception. Pointed invalid entity type - '${entityType}'`);
}

export function getRequestBody(requestBody: any, dirtyFields: any, getValues: any) {
    let body = {} as any;
    let getDirtyValue = (key: string): any => {
        let value = '-1';
        if (key in dirtyFields && getValues(key)) {
            value = getValues(key);
        }
        return value;
    };

    for (let key in requestBody) {
        let arg = requestBody[key].query_arg;
        let keyValues = getDirtyValue(key);
        body[arg] = { values: keyValues instanceof Array ? keyValues : [keyValues] };
    }

    return {
        filters: {
            values: {
                ...body,
            },
        },
    };
}
